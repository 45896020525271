import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocation,
  faPhone,
  faGlobe,
  faClock
} from '@fortawesome/pro-solid-svg-icons'


import Layout from '../../components/layout'
import './styles.scss'
import styles from './styles.module.scss'

export default ({ pageContext }) => {
  return (
    <Layout title={pageContext.seo.title}>
      <div className="Location search flex flex-col items-center content-center justify-center p-12">
        <h1 className="text-4xl mb-8">{pageContext.title}</h1>
      </div>

      <section className={`${styles.container} py-16`}>
        {pageContext.items.map(item => (
          <div key={item._id} className={`${styles.item} text-xs mb-8 rounded-lg`}>
            <div className={`${styles.title} flex items-center justify-between`}>
              <div className="text-xl py-3 px-4">{item.label}</div>
            </div>

            {item.address && <div className="flex items-center border-b border-gray-400 mx-2">
              <div className="p-3">
                <FontAwesomeIcon icon={faLocation} />
              </div>
              <div className="p-3 text-gray-500">
                {item.address}
              </div>
            </div>}
            {item.phone && <div className="flex items-center border-b border-gray-400 mx-2">
              <div className="p-3">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="p-3 text-gray-500">
                {item.phone}
              </div>
            </div>}
            {item.site && <div className="flex items-center border-b border-gray-400 mx-2">
              <div className="p-3">
                <FontAwesomeIcon icon={faGlobe} />
              </div>
              <div className="p-3">
                <a target="_blank" rel="noopener noreferrer" className="text-gray-500" href={item.site}>{item.site.replace('http', '').replace('https', '')}</a>
              </div>
            </div>}
            {Array.isArray(item.ranges) && item.ranges.length > 0 && <div className="flex items-center mx-2">
              <div className="p-3">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <div className="p-3 flex items-center">
                {item.ranges.map(range => <div key={`range-${item._id}-${JSON.stringify(range)}`} className="flex items-center mr-2">
                  <div className="mr-2 ">{range.wdays}</div> <div className="text-gray-500">{range.hours}</div>
                </div>)}
              </div>
            </div>}
          </div>
        ))}
      </section>

    </Layout>
  )
}
